$(document).ready(() => {

  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click(e => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  $('.bundle-slider').slick({
    arrows: false,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  $('.product-prev').click(function() {
    $('.bundle-slider').slick('slickPrev');
  });

  $('.product-next').click(function() {
    $('.bundle-slider').slick('slickNext');
  });

  AOS.init();
});
